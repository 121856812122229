/** @jsx jsx */
import PropTypes from 'prop-types';
import { Text as TextBass } from '@rebass/emotion';
import { jsx } from '@emotion/core';

const Text = ({ theme, children, lineHeight, ...props }) => {
  return (
    <TextBass css={theme => ({
        lineHeight: lineHeight ? lineHeight : '1.6em',
      })}
      {...props}
    >
      {children}
    </TextBass>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Text;

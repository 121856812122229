const colors = {
  blue: '#0A00AA',
  lightBlue: '#C4CAFE',
  lightBeige: '#F9F6F2',
  lightGray: '#B0B0BE',
  mediumGray: '#585B6C',
  darkGray: '#282B3A',
  white: '#FFFFFF',
}

const fonts = {
  sans: "'neuzeit-grotesk', 'Helvetica Neue', Helvetica, system-ui, sans-serif",
}

const borders = {
  light: '1px solid #EFEFEF',
  white: '1px solid #FFFFFF',
  darkGray: '1px solid #202331',
  blue: '1px solid #0A00AA',
}

const borderRadius = [
  '4px',
]

const fontWeights = {
  light: 300,
  regular: 400,
  bold: 700,
}

const breakpoints = [
  '40em',
  '72em',
  '86em',
]

const lineHeights = [
  '1.125em',
  '1.333em',
  '1.666em',
]

const fontSizes = [
  '1rem',
  '1.125rem',
  '1.25rem',
  '1.375rem',
  '1.75rem',
  '2rem',
  '2.625rem',
  '3.625rem',
  '4.5rem',
]

const space = [
  0, 4, 8, 16, 24, 32, 64, 128
]

const theme = {
  borders,
  borderRadius,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  space,
}

export default theme;

/** @jsx jsx */
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Link as LinkBass, Text } from '@rebass/emotion';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

const Button = ({ theme, children, href, light, small, ...props }) => {
  const StyledButton = styled(Link)`
    border-radius: ${props => props.theme.borderRadius[0]};
    display: inline-block;
    text-decoration: none;
    border: ${light ? props => props.theme.borders.white : props => props.theme.borders.darkGray};
    color: ${light ? props => props.theme.colors.white : props => props.theme.colors.darkGray};
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${light ? props => props.theme.colors.white : props => props.theme.colors.darkGray};
      color: ${light ? props => props.theme.colors.darkGray : props => props.theme.colors.white};
    }
  `;
  const StyledLink = styled(LinkBass)`
    border-radius: ${ props => props.theme.borderRadius[0]};
    display: inline-block;
    text-decoration: none;
    border: ${light ?  props => props.theme.borders.white : props => props.theme.borders.darkGray};
    color: ${light ?  props => props.theme.colors.white : props => props.theme.colors.darkGray};
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${light ?  props => props.theme.colors.white : props => props.theme.colors.darkGray};
      color: ${light ?  props => props.theme.colors.darkGray : props => props.theme.colors.white};
    }
  `;

  return (
    <Fragment>
      {!href &&
      <StyledButton
        {...props}
      >
        <Text m={0} fontSize={small ? [0, 1] : [1, 1]} px={small ? ['10px', '12px', '14px'] : ['12px', '18px', '20px']} py={small ? ['6px', '8px', '10px'] : ['10px', '12px', '12px']}>{children}</Text>
      </StyledButton>
      }
      {href &&
        <StyledLink
          href={href}
          {...props}
        >
          <Text m={0} fontSize={small ? [0, 1] : [1, 1]} px={small ? ['10px', '12px', '14px'] : ['12px', '18px', '20px']} py={small ? ['6px', '8px', '10px'] : ['10px', '12px', '12px']}>{children}</Text>
        </StyledLink>
      }
    </Fragment>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import emotionNormalize from 'emotion-normalize';
import { Global, css } from '@emotion/core';

import aoscss from 'aos/dist/aos.css';
import animate from '../theme/animate.css';

import Header from '../modules/Header';
import Footer from '../modules/Footer';
import FooterContact from '../modules/FooterContact';
import theme from '../theme/theme';

class Layout extends Component {

  componentDidMount() {
      const AOS = require('aos');
      this.aos = AOS;
      this.aos.init({
        duration: 800,
        offset: 0,
      });
  }

  componentDidUpdate() {
      this.aos.refresh();
  }

  render() {
    const { children, dark, location } = this.props;
    return (
          <ThemeProvider theme={theme}>
            <Global
              styles={css`
                ${emotionNormalize}
                * {
                  box-sizing: border-box;
                }
                html,
                body {
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  background-color: ${theme.colors.darkGray};
                  color: ${theme.colors.mediumGray};
                  font-family: ${theme.fonts.sans};
                  font-size: ${theme.fontSizes[0]};
                  font-weight: normal;
                }
                img {
                  display: block;
                  max-width: 100%;
                }
                ${animate}
                ${aoscss}
              `}
            />
            <Header dark={dark} />
            <main>{children}</main>
            {!location &&
              <FooterContact />
            }
            <Footer />
          </ThemeProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

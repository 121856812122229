import React from 'react';
import styled from '@emotion/styled';
import { Flex } from  '@rebass/emotion';

const ImageContainer = styled(Flex)`
  margin: 36px 0 0 0;
  width: 100%;
  > div {
    flex: 1;
  }
  & div {
    margin-bottom: 0;
  }
  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    margin-left: -10%;
    width: 120%;
  }
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    margin-left: -20%;
    width: 140%;
  }
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    margin-left: -45%;
    width: 190%;
  }
`;

const ImgContainer = ({ children }) => (
  <ImageContainer data-aos="fade-up" flexDirection={["column", "column", "row"]}>
    {children}
  </ImageContainer>
)

export default ImgContainer;

/** @jsx jsx */
import PropTypes from 'prop-types';
import { Heading as HeadingBass } from '@rebass/emotion';
import { jsx } from '@emotion/core';

const Heading = ({ theme, children, ...props }) => {
  return (
    <HeadingBass css={theme => ({
        fontWeight: 'normal',
        letterSpacing: '-0.5px',
      })}
      {...props}
    >
      {children}
    </HeadingBass>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Heading;

import React from 'react';
import styled from '@emotion/styled';
import { Text } from '@rebass/emotion';

const CaptionContainer = styled(Text)`
  display: block;
  font-size: ${props => props.theme.fontSizes[1]};
`;

const CapContainer = ({ children }) => (
  <CaptionContainer>
    {children}
  </CaptionContainer>
)

export default CapContainer;

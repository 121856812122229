/** @jsx jsx */
import { Component } from 'react';
import { Box } from '@rebass/emotion';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Button from '../components/Button';
import Wrap from '../components/Wrap';
import Text from '../components/Text';
import Drawer from '../components/Drawer';

import Citra from '../images/citra-logo.inline.svg';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state ={
      open: false,
    }
  }

  openModal = () =>  {
    this.setState({open: true});
  }

  closeModal = () => {
    this.setState({open: false});
  }

  render() {
    const { open } = this.state;
    const { dark, ...props } = this.props;
    const CitraLogo = styled(Citra)`
      fill: ${dark ? '#282B3A' : '#ffffff'};
      max-width: 100%;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 2;
    `;

    const CitraLogoDrawer = styled(Citra)`
      fill: #ffffff;
      max-width: 100%;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 2;
    `;

    const Container = styled('header')`
      background-color: ${dark ? '#F9F6F2' : 'transparent'};
    `;

    const NavLink = styled(Link)`
      color: ${props => props.theme.colors.white};
      display: inline-block;
      text-decoration: none;
      &:hover, &:visited, &:active {
        color: ${props => props.theme.colors.white};
      }
    `;

    const DrawerLink = styled(Link)`
      color: ${props => props.theme.colors.white};
      text-decoration: none;
      &:hover, &:visited, &:active {
        color: ${props => props.theme.colors.white};
      }
    `;

    const MobileOnly = styled(Box)`
      display: block;
      @media (min-width: ${props => props.theme.breakpoints[0]}) {
        display: none;
      }
    `;

    const DesktopOnly = styled(Box)`
      display: none;
      @media (min-width: ${props => props.theme.breakpoints[0]}) {
        display: block;
      }
    `;

    const Hamburger = styled('a')`
      cursor: pointer;
      display: flex;
      float: right;
      align-items: flex-end;
      flex-direction: column;
      width: 42px;
      height: 28px;
      position: relative;
      top: 7px;
      margin-left: 8px;
  `;

    const HamburgerIcon = styled(Box)`
      height: 2px;
  `;

    const CloseIcon = styled(Box)`
      transform: rotate(45deg) translateY(6px);
      height: 2px;
    `;

    const CloseIcon2 = styled(Box)`
      transform: rotate(-45deg) translateY(-6px);
      height: 2px;
    `;

    const CloseButton = styled('a')`
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: 42px;
      height: 42px;
      position: absolute;
      top: 24px;
      right: 24px;
  `;

    return (
      <Container {...props}>
        <nav>
            <Wrap flexWrap="wrap" alignItems="center" py={[3, 3]}>
              <Box alignSelf="flex-start">
                <Box width={['90px', '100px', '125px']} mt={[2, 4]}><Link to="/"><CitraLogo /></Link></Box>
              </Box>
              <Box flex="1" />
              <Box alignSelf="flex-end" mt={[0, 3, 3]} style={{ position: 'relative', zIndex: '2' }}>
                <DesktopOnly>
                  <NavLink color="white" to="/projects"><Text color={ dark ? 'darkGray' : 'white' } px={[2, 4]} fontSize={[0, 1]}>Our Work</Text></NavLink>
                  <NavLink color="white" to="/contact"><Text color={ dark ? 'darkGray' : 'white' } px={[2, 4]} fontSize={[0, 1]}>Contact</Text></NavLink>
                  <Button
                      light={ dark ? false : true }
                      small
                      ml={[2, 4]}
                      target="_blank"
                      href="https://calendly.com/citra-studio/consultation/"
                    >Schedule a Call</Button>
                </DesktopOnly>
                <MobileOnly>
                  <Button
                      light={ dark ? false : true }
                      small
                      ml={[2, 4]}
                      target="_blank"
                      href="https://calendly.com/citra-studio/consultation/"
                    >Schedule a Call</Button>
                    <Hamburger href="#" onClick={this.openModal}>
                      <HamburgerIcon mb={'6px'} width={'28px'} bg={ dark ? 'darkGray' : 'white' } />
                      <HamburgerIcon mb={'6px'}  width={'28px'} bg={ dark ? 'darkGray' : 'white' } />
                      <HamburgerIcon mb={'6px'}  width={'20px'} bg={ dark ? 'darkGray' : 'white' } />
                    </Hamburger>
                    <Drawer
                        open={open}
                        onRequestClose={this.closeModal}
                    >
                      <Box alignSelf="flex-start" style={{ borderBottom: '1px solid rgba(255,255,255,0.2)' }}>
                        <Box width={['100px', '100px', '125px']} mb={[3, 3]}><Link to="/"><CitraLogoDrawer /></Link></Box>
                      </Box>
                      <CloseButton href="#" onClick={this.closeModal}>
                        <CloseIcon mb={'6px'} width={'28px'} bg="white" />
                        <CloseIcon2 mb={'6px'} width={'28px'} bg="white" />
                      </CloseButton>
                      <DrawerLink color="white" to="/projects" onClick={this.closeModal}><Text color="white" pt={4} fontSize={[5, 6]}>Our Work</Text></DrawerLink>
                      <DrawerLink color="white" to="/contact" onClick={this.closeModal}><Text color="white" pt={3} fontSize={[5, 6]}>Contact</Text></DrawerLink>
                  </Drawer>
                </MobileOnly>
              </Box>
          </Wrap>
        </nav>
      </Container>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;

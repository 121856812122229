/** @jsx jsx */
import PropTypes from 'prop-types';
import { Text } from '@rebass/emotion';
import { jsx } from '@emotion/core';

const ListItem = ({ theme, children, ...props }) => {
  return (
    <Text as="li" css={theme => ({
        margin: '0',
        padding: '0'
      })}
      {...props}
    >
      {children}
    </Text>
  );
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListItem;

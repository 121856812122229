import React from 'react';
import { Box, Flex } from '@rebass/emotion';
import styled from '@emotion/styled';

import Wrap from '../components/Wrap';
import Text from '../components/Text';
import Citra from '../images/citra-logo.inline.svg';

const FooterContainer = styled(Box)`
  background-color: ${props => props.theme.colors.darkGray};
  border-top: 1px solid rgba(255,255,255,0.2);
  position: relative;
  z-index: 1;
`;

const CitraLogo = styled(Citra)`
  fill: #FFFFFF;
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const Footer = () => (
  <footer>
    <FooterContainer>
      <Wrap flexWrap="wrap" py={['4%', '2%']}>
        <Box alignSelf="flex-start">
          <Flex flexWrap="wrap" alignItems="center">
            <Box width={['80px', '90px', '90px']}><CitraLogo /></Box>
            <Text color="lightGray" ml={3} pt="4px" fontSize={[0, 1, 1]}>Product Studio</Text>
          </Flex>
        </Box>
        <Box flex="1" />
        <Box alignSelf="flex-end">
          <Text color="lightGray">&copy; 2019 Citra</Text>
        </Box>
      </Wrap>
    </FooterContainer>
  </footer>
);

export default Footer;

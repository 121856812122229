import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styled from '@emotion/styled';

Modal.setAppElement('body');

const Drawer = ({ open, onRequestClose, children, ...props }) => {
  const ModalStyled = styled(Modal)`
    background-color: #212434;
    width: 90%;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    outline: none;
    padding: 20px 32px;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
      display: none;
    }
  `;
  return (
    <ModalStyled
      closeTimeoutMS={300}
      isOpen={open}
      contentLabel="Navigation"
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
    >
      {children}
    </ModalStyled>
  );
};

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Drawer;

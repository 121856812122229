/** @jsx jsx */
import PropTypes from 'prop-types';
import { Flex } from '@rebass/emotion';
import { jsx, css } from '@emotion/core';

const Wrap = ({ theme, children, ...props }) => {
  return (
    <Flex css={css`
      margin-left: auto;
      margin-right: auto;
      max-width: 85%;
      @media (min-width: 1440px) {
        max-width: 1224px;
      }
    `}
      {...props}
    >
      {children}
    </Flex>
  );
};

Wrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wrap;

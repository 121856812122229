/** @jsx jsx */
import PropTypes from 'prop-types';
import { Box } from '@rebass/emotion';
import { jsx } from '@emotion/core';

const List = ({ theme, children, ...props }) => {
  return (
    <Box as="ul" css={theme => ({
        margin: '0',
        padding: '0',
      })}
      {...props}
    >
      {children}
    </Box>
  );
};

List.propTypes = {
  children: PropTypes.node.isRequired,
};

export default List;

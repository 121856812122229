import React from 'react';
import Highlight, {defaultProps} from 'prism-react-renderer';
import dracula from 'prism-react-renderer/themes/dracula';
import styled from '@emotion/styled';

const Code = ({children, className}) => {
  const codeBlock = children.toString().trim();
  const StyledPre = styled('pre')`
    border-radius: 4px;
    overflow: auto;
    padding:24px;
  `;
  return (
    <Highlight
      {...defaultProps}
      code={codeBlock}
      language="jsx"
      theme={dracula}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <StyledPre data-aos="fade-up" className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </StyledPre>
      )}
  </Highlight>
  )
}

export default Code;

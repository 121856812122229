import React from 'react';
import PropTypes from 'prop-types';
import rehypeReact from 'rehype-react';
import { Divider, Image, Link } from '@rebass/emotion';
import Heading from './Heading';
import Text from './Text';
import ListItem from './ListItem';
import List from './List';
import ImageContainer from './ImageContainer';
import ImageCaption from './ImageCaption';
import Code from './Code';

const HeadingPrimary = ({ children }) => <Heading mt={[5, 6]} as="h1" color="darkGray" fontSize={[5, 7]} mb={3} data-aos="fade-up" data-aos-offset="0">{children}</Heading>
const HeadingSecondary = ({ children }) => <Heading mt={[5, 6]} as="h2" color="darkGray" fontSize={[3, 5]} mb={3} data-aos="fade-up" data-aos-offset="0">{children}</Heading>
const HeadingTertiary = ({ children }) => <Heading mt={[5, 6]} as="h3" color="darkGray" fontSize={[3, 5]} mb={3} data-aos="fade-up" data-aos-offset="0">{children}</Heading>
const Paragraph = ({ children }) => <Text fontSize={[1, 2]} mb={4} data-aos="fade-up" data-aos-offset="0">{children}</Text>
const UlList = ({ children }) => <List ml={3} data-aos="fade-up" data-aos-offset="0">{children}</List>
const LiListItem = ({ children }) => <ListItem fontSize={3} mb={3}>{children}</ListItem>
const ImageWrapper = ({ children }) => <ImageContainer>{children}</ImageContainer>
const CaptionWrapper = ({ children }) => <ImageCaption  data-aos="fade-up" data-aos-offset="0">{children}</ImageCaption>
const CodeWrapper = ({ children }) => <Code className="code">{children}</Code>

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "h1": HeadingPrimary,
    "h2": HeadingSecondary,
    "h3": HeadingTertiary,
    "p": Paragraph,
    "blockquote": Paragraph,
    "hr": Divider,
    "a": Link,
    "ul": UlList,
    "li": LiListItem,
    "img": Image,
    "figure": ImageWrapper,
    "aside": CaptionWrapper,
    "code": CodeWrapper,
   },
}).Compiler;

export const HTMLContent = ({ content }) => (
  <div>{renderAst(content)}</div>
);

const Content = ({ content }) => (
  <div>{content}</div>
);

Content.propTypes = {
  content: PropTypes.shape({
    children: PropTypes.array,
  }),
};

HTMLContent.propTypes = Content.propTypes;

export default Content;

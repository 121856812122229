import React from 'react';
import { Box } from '@rebass/emotion';

import Wrap from '../components/Wrap';
import Heading from '../components/Heading';
import Button from '../components/Button';

const FooterContact = () => (
  <Box py={['16%', '8%']}>
    <Wrap flexWrap="wrap">
      <Box width={1} ml={[0, '10%']}>
        <Heading data-aos="fade-up" mb={4} color="white" as="h3" fontSize={[ 5, 6, 6 ]} lineHeight={1}>Let's build something together <span role="img" aria-label="Rocket Ship">🚀</span></Heading>
        <Button
            data-aos="fade-up"
            delay="100"
            light
            to="/contact/"
          >Contact Us</Button>
        </Box>
    </Wrap>
  </Box>
);

export default FooterContact;

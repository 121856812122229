import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/emotion';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Content, { HTMLContent } from '../components/Content';
import Wrap from '../components/Wrap';
import Heading from '../components/Heading';
import Text from '../components/Text';
import Button from '../components/Button';

export const ProjectPost = ({
  content,
  contentComponent,
  description,
  expertise,
  headerImage,
  platform,
  title,
  url,
  year,
}) => {
  const ProjectContent = contentComponent || Content;
  return (
    <Layout>
      <SEO image={`https://citra.co${headerImage.childImageSharp.fluid.src}`} title={`Citra. ${title} - Case Study`} keywords={[`contact`, `ux`, `product`, `design`, `studio`]} />
      <Box bg="darkGray">
        <Wrap py={['12%', '6%']} flexWrap="wrap">
          <Box width={[1, 6/10, 5/10]}>
            <Heading data-aos="fade-up" as="h2" color="white" pb={3} fontSize={[5, 6, 7]}>{title}</Heading>
            <Text color="white" data-aos="fade-up" fontSize={[2, 3]}>{description}</Text>
          </Box>
          <Box data-aos="fade-up" width={[1, 4/10, 5/10 ]} pt={['8%', 0]} pl={['0', '10%']}>
            <Box width={1} py={[2, 3]} pr={3}>
              <Text fontSize={0} color="white">Expertise</Text>
              <Text fontSize={[1, 2]} color="white">
                {expertise.map((expertise2, i) => (
                  <span key={expertise2}>
                    { (i ? ', ' : '') + expertise2 }
                  </span>
                ))}
              </Text>
            </Box>
            <Box width={1} py={[2, 3]} pr={3}>
              <Text fontSize={0} color="white">Platform</Text>
              <Text fontSize={[1, 2]} color="white">
                {platform.map((platform2, i) => (
                  <span key={platform2}>
                    { (i ? ', ' : '') + platform2 }
                  </span>
                ))}
              </Text>
            </Box>
            <Box width={1} py={[2, 3]} pr={3}>
              <Button light href={url}>View Project</Button>
            </Box>
          </Box>
        </Wrap>
        <Box data-aos="fade-up">
          <Img fluid={headerImage.childImageSharp.fluid} />
        </Box>
      </Box>
      <Flex bg="white" flexWrap="wrap" justifyContent="center">
        <Box width={[11/12, 9/12, 8/12, 6/12]} pb={'8%'} pt={0}>
          <ProjectContent content={content} />
        </Box>
      </Flex>
    </Layout>
  )
};

const ProjectTemplate = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <ProjectPost
      content={post.htmlAst}
      contentComponent={HTMLContent}
      description={post.frontmatter.description}
      expertise={post.frontmatter.expertise}
      headerImage={post.frontmatter.headerImage}
      platform={post.frontmatter.platform}
      year={post.frontmatter.date}
      title={post.frontmatter.title}
      url={post.frontmatter.url}
    />
  );
};

export default ProjectTemplate;

ProjectTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        date: PropTypes.string,
        description: PropTypes.string,
        expertise: PropTypes.array,
        headerImage: PropTypes.array,
        platform: PropTypes.array,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  }),
};

export const pageQuery = graphql`
  query ProjectPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "YYYY")
        expertise
        headerImage {
          childImageSharp {
            fluid(maxWidth: 2880) {
            src
              ...GatsbyImageSharpFluid
            }
          }
        }
        platform
        title
        url
        description
      }
    }
  }
`;
